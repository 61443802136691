<template>
  <div class="container">
    <div class="main-bgr"></div>
    <div class="section">
      <div class="row in-grid">
        <div class="columns-wrapper center title">
          <div class="col-12">
            <h3>Je poškodenie v zornom poli vodiča? </h3>
            <p>Zorné pole je 30 cm široký pás pred vodičom, zhruba v šírke volantu.</p>
          </div>
        </div>
        <div class="columns-wrapper center boxes">
          <div class="col-6">
            <div class="box" @click="selected(0)">
              <img src="/assets/images/icon-krok5-zorne-ano.svg">
              <p><strong>Áno</strong>, je v zornom poli</p>
            </div>
          </div>
          <div class="col-6">
            <div class="box" @click="selected(1)">
              <img src="/assets/images/icon-krok5-zorne-nie.svg">
              <p><strong>Nie</strong> je v zornom poli</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StepFive',
    emits: 'switch-page',
    props: ['form'],
    data() {
      return {
        localform: this.form
      }
    },
    methods: {
      selected(id) {
        this.localform.viewing_angle = id;
        this.$emit('switch-page', 'StepSix');
      }
    }
  }
</script>


