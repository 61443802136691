<template>
  <div class="container">
    <div class="main-bgr"></div>

    <div class="section">
      <div class="row in-grid">
        <div class="columns-wrapper center title">
          <div class="col-12">
            <h3>Uveďte Vaše tel. číslo</h3>
            <p>bude slúžiť ako registračná značka pre Váš dopyt</p>
          </div>
        </div>
        <div class="columns-wrapper center">
          <div class="col-4"></div>
          <div class="col-4">
            <div class="input-field">
              <label for="phone">Telefónne číslo:</label>
              <input type="tel"
                     id="phone"
                     name="phone"
                     v-model="phone"
                     placeholder="+421 XXX XXX XXX" required>
            </div>
            <div class="input-field" id="grprpar" v-if="this.loggedIn === false">
              <input class="styled-checkbox" id="gdpr" type="checkbox" value="gdpr" required>
              <label for="gdpr" class="styled-checkbox-label" id="gdpr">Súhlasím so <a href="https://www.hornet.sk/zasady-ochrany-osobnych-udajov/" target="_blank">spracovaním osobných údajov</a> pre vytvorenie cenovej ponuky</label>
            </div>
            <a class="app-btn" href="#" @click="nextPage()"><span>Pokračovať</span></a>
          </div>
          <div class="col-4"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StepTwo',
    emits: 'switch-page',
    props: ['form', 'loggedIn'],
    data() {
      return {
        phone: this.form.telephone,
        localform: this.form
      }
    },
    methods: {
      nextPage() {
        document.getElementById('phone').labels[0].style.color = 'black'

        if (this.loggedIn === false) {
          document.getElementById('gdpr').labels[0].style.color = 'black'
          if (document.getElementById('gdpr').checked === false) {
            document.getElementById('gdpr').labels[0].style.color = 'red'
          } else {
            this.sendNextPage();
          }
        } else {
          this.sendNextPage();
        }
      },
      sendNextPage() {
        const validationRegex = /^\+?[0-9]+$/;
        let numb = this.phone.replace(/\s/g,'')
        if (numb.match(validationRegex) || this.loggedIn) {
          this.localform.telephone = numb;
          if (this.localform.service == 0) {
            this.$emit('switch-page', 'StepThree');
          } else {
            this.$emit('switch-page', 'StepSix');
          }
        } else {
          alert("Neplatné telefónne číslo. Zadajte prosím telefónne číslo v tvare +421901123456 alebo 0901123456.");
        }
      }
    },
    mounted() {
      if(this.phone != '' && this.loggedIn === false) {
        document.getElementById('gdpr').checked = true;
      }
    }
  }
</script>


