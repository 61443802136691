<template>
  <div class="container">
    <div class="main-bgr"></div>
    <div class="section">
      <div class="row in-grid">
        <div class="columns-wrapper center">
          <div class="col-12">
            <h3>Zadajte adresu miesta opravy</h3>
          </div>
        </div>
        <div class="columns-wrapper">
          <div class="col-6 form">
            <div class="input-field">
              <label for="street">Ulica</label>
              <input type="text" id="street" name="street" v-model="street" >
            </div>
            <div class="input-field">
              <label for="city">Mesto</label>
              <input type="text" id="city" name="city" v-model="city" >
            </div>
            <div class="input-field">
              <label for="zip">PSČ</label>
              <input type="text" id="zip" name="zip" v-model="zip" >
            </div>
            <div class="input-field">
              <label for="details">Bližšie informácie o mieste</label>
              <input type="text" id="details" name="details" v-model="details" placeholder="napr. parkovisko Tesco" >
            </div>
          </div>
          <div class="col-6 info-pocasie">
            <div class="pocasie-wrapper">
              <img src="/assets/images/icon-pocasie.svg">
              <div class="pocasie-text">
                <h4>Pozor na nepriaznivé počasie!</h4>
                <p>Ak si vyberiete mobilný servis, je potrebné, aby ste si v prípade nepriaznivého počasia (dažďa, sneženia) zabezpečili priestor s prístreškom, poprípade garáž, kde bude oprava/výmena realizovaná.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="columns-wrapper center">
          <div class="col-12">
            <a class="app-btn" href="#"  @click="nextPage"><span>Pokračovať</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StepEightB',
    emits: 'switch-page',
    props: ['form'],
    data() {
      return {
        street: this.form.mobile_street,
        city: this.form.mobile_city,
        zip: this.form.mobile_zip,
        details: this.form.mobile_details,
        localform: this.form
      }
    },
    methods: {
      nextPage() {
          this.localform.service_place = 0;
          this.localform.mobile_street = this.street;
          this.localform.mobile_city = this.city;
          this.localform.mobile_zip = this.zip;
          this.localform.mobile_details = this.details;
          this.$emit('switch-page', 'StepNine');
      }
    }
  }
</script>


