<template>
  <div class="container">
    <div class="main-bgr"></div>
    <div class="section">
      <div class="row in-grid">
        <div class="columns-wrapper center title">
          <div class="col-12">
            <h3>Je vozidlo havarijne poistené alebo má pripoistenie skiel?</h3>
          </div>
        </div>
        <div class="columns-wrapper center">
          <div class="col-12">
            <a href="#" class="outline-btn" id="insurance-yes" style="margin-right: 20px;" @click="showInsurance">Áno</a>
            <a href="#" class="outline-btn" id="insurance-no" @click="selected(0)">Nie</a>
          </div>
        </div>
      </div>
    </div>

    <div class="section poistovne hidden" id="insurers">
      <div class="row in-grid">
        <div class="columns-wrapper center title">
          <div class="col-12">
            <h3>Vyberte si vašu poisťovňu</h3>
          </div>
        </div>
        <div class="columns-wrapper poistovnetext">
          <div class="col-12">
            <p>Pri oprave čelného skla neplatíte nič, na opravu sa nevzťahuje spoluúčasť, a nezvýši poistné<br>
              Pri výmene škodu hradí vaša poisťovňa, vy zaplatíte len minimálnu spoluúčasť<br><br></p>
          </div>
        </div>
        <div class="columns-wrapper center boxes poistovne">
          <div class="col-2"><div class="box" @click="selected(1)"><img src="/assets/images/poistovne/logo-poistovna-alianz.svg"></div></div>
          <div class="col-2"><div class="box" @click="selected(2)"><img src="/assets/images/poistovne/logo-poistovna-kooperativa.svg"></div></div>
          <div class="col-2"><div class="box" @click="selected(3)"><img src="/assets/images/poistovne/logo-poistovna-uniqa.svg"></div></div>
          <div class="col-2"><div class="box" @click="selected(4)"><img src="/assets/images/poistovne/logo-poistovna-komunalna.svg"></div></div>
          <div class="col-2"><div class="box" @click="selected(5)"><img src="/assets/images/poistovne/logo-poistovna-groupama.svg"></div></div>
          <div class="col-2"><div class="box" @click="selected(6)"><img src="/assets/images/poistovne/logo-poistovna-csob.svg"></div></div>
          <div class="col-2"><div class="box" @click="selected(7)"><img src="/assets/images/poistovne/logo-poistovna-generali.svg"></div></div>
          <div class="col-2"><div class="box" @click="selected(8)"><img src="/assets/images/poistovne/logo-poistovna-union.svg"></div></div>
          <div class="col-2"><div class="box" @click="selected(9)"><img src="/assets/images/poistovne/logo-poistovna-axa.svg"></div></div>
          <div class="col-2"><div class="box" @click="selected(10)"><img src="/assets/images/poistovne/logo-poistovna-wustenrot.svg"></div></div>
          <div class="col-2"><div class="box" @click="selected(11)"><img src="/assets/images/poistovne/logo-poistovna-genertel.svg"></div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StepSix',
    emits: 'switch-page',
    props: ['form'],
    data() {
      return {
        localform: this.form
      }
    },
    methods: {
      showInsurance() {
        document.getElementById('insurance-yes').classList.add('active');
        document.getElementById('insurers').style.display = 'block';
      },
      selected(id) {
        this.localform.insurance = id;
        this.$emit('switch-page', 'StepSeven');
      }
    }
  }
</script>
