<template>
  <div class="container">
    <div class="main-bgr"></div>
    <div class="section">
      <div class="row in-grid">
        <div class="columns-wrapper center title">
          <div class="col-12">
            <h3>Ďakujeme za prejavený záujem</h3>
          </div>
        </div>
        <div class="columns-wrapper center">
          <div class="col-2"></div>
          <div class="col-8">
            <p>Váš dopyt bol odoslaný, ponuka Vám bude zaslaná obratom na zadaný e-mail. Väčšinou do 2 hodín (najneskôr nasledujúci pracovný deň) sa Vás pokúsime kontaktovať pre doplnenie potrebných informácií.</p>
            <br>
            <p>*Ak ste dopyt vytvorili mimo pracovných dní, pokúsime sa Vás kontaktovať najbližší prac. deň.</p>
          </div>
          <div class="col-2"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StepEleven',
    props: ['form'],
    data() {
      return {
      }
    },
    methods: {
    }
  }
</script>


