<template>

  <div class="container">
    <div class="main-bgr"></div>
    <div class="section">
      <div class="row in-grid">
        <form ref="form" action="/new_order" method="post" class="columns-wrapper input-field">
          <div class="col-6">

            <h3>Vyplňte údaje, aby sme Vám zaslali online ponuku.</h3>
            <div class="form">

              <input type="hidden" name="_token" v-model="csrf">
              <input type="hidden" name="service" v-model="service">
              <input type="hidden" name="size" v-model="size">
              <input type="hidden" name="place" v-model="place">
              <input type="hidden" name="viewing_angle" v-model="viewing_angle">
              <input type="hidden" name="service_place" v-model="service_place">
              <input type="hidden" name="mobile_street" v-model="mobile_street">
              <input type="hidden" name="mobile_city" v-model="mobile_city">
              <input type="hidden" name="mobile_zip" v-model="mobile_zip">
              <input type="hidden" name="mobile_details" v-model="mobile_details">
              <input type="hidden" name="service_point" v-model="service_point">
              <input type="hidden" name="service_place_id" v-model="service_place_id">
              <input type="hidden" name="date" v-model="date">
              <input type="hidden" name="insurance" v-model="insurance" v-if="!this.loggedIn">
              <input type="hidden" id="status" name="status" v-model="status" v-if="this.loggedIn">

              <div class="columns-wrapper">
                <div class="col-6 final-step-columns">
                  <label for="name">Meno a priezvisko/Názov firmy</label>
                  <input type="text" id="name" name="name" v-model="name" @change="this.localform.name = this.name" required>
                </div>

                <div class="col-6 final-step-columns" v-if="this.loggedIn">
                  <label for="insurance">Poisťovňa</label>
                  <select class="form-select" id="insurance" name="insurance" v-model="insurance_pos" @change="this.localform.insurance = this.insurance">
                    <option v-for="(item, index) in this.form_data.insurance" :key="index" :value="index">
                      <span v-if="index > 0">Havarijné poistenie</span> {{item}}
                    </option>

                    <option value="12">Povinné zmluvné poistenie Allianz</option>
                    <option value="13">Povinné zmluvné poistenie Axa</option>
                    <option value="14">Povinné zmluvné poistenie Kooperatíva</option>
                    <option value="15">Povinné zmluvné poistenie Komunálna poisťovňa</option>
                    <option value="16">Povinné zmluvné poistenie Generali</option>
                    <option value="17">Povinné zmluvné poistenie Genertel</option>
                    <option value="18">Povinné zmluvné poistenie Groupama</option>
                    <option value="19">Povinné zmluvné poistenie Union</option>
                    <option value="20">Povinné zmluvné poistenie Uniqa</option>
                    <option value="22">Povinné zmluvné poistenie ČSOB poisťovňa</option>
                    <option value="23">Povinné zmluvné poistenie Wustenrot</option>
                    <option value="24">Povinné zmluvné poistenie QBE poisťovňa</option>
                    <option value="25">Havarijné poistenie Astra</option>
                    <option value="26">Povinné zmluvné poistenie Astra</option>
                  </select>
                </div>

                <div class="col-6 final-step-columns">
                  <label for="spz">ŠPZ</label>
                  <input type="text" id="spz" name="spz" v-model="spz" @change="this.localform.spz = this.spz">
                </div>

                <div class="col-6 final-step-columns" v-if="this.loggedIn">
                  <label for="resolved">Spôsob riešenia</label>
                  <select class="form-select" id="resolved" name="resolved" v-model="resolved">
                    <option value="1">Havarijné poistenie</option>
                    <option value="2" >Havarijné poistenie+PZP</option>
                    <option value="3">Povinné zmluvné poistenie</option>
                    <option value="4">Poistenie zodpovednosti (budova, osoba, spoločnosť)</option>
                    <option value="5">Vlastná réžia</option>
                    <option value="6">Hornet ASSIST</option>
                  </select>
                </div>

                <div class="col-6 final-step-columns" v-if="this.loggedIn">
                  <label for="vin">VIN</label>
                  <input type="text" id="vin" name="vin" v-model="vin" placeholder="VIN číslo karosérie" :maxlength="17" @change="this.localform.vin = this.vin">
                </div>

                <div class="col-6 final-step-columns">
                  <label for="phone">Telefónne číslo</label>
                  <input type="tel" id="phone" name="phone" v-model="phone" placeholder="+421 XXX XXX XXX" @change="this.localform.phone = this.phone" required>
                </div>

                <div class="col-6 final-step-columns" v-if="this.loggedIn">
                  <label for="eucode">Eurokód skla</label>
                  <input type="text" id="eucode" name="eucode" v-model="eucode" @change="this.localform.eucode = this.eucode">
                </div>

                <div class="col-6 final-step-columns">
                  <label for="email">Váš email</label>
                  <input type="email" id="email" name="email" v-model="email" @change="this.localform.email = this.email">
                </div>

                <div class="col-6 final-step-columns" v-if="this.loggedIn">
                  <label for="wipers">Kedy ste menili stierače</label>
                  <select class="form-select" id="wipers" name="wipers" v-model="wipers" @change="this.localform.wipers = this.wipers">
                    <option value="0">neuzavreté</option>
                    <option value="1">menej ako rok</option>
                    <option value="2">viac ako rok</option>
                    <option value="3">viac ako 2 roky</option>
                  </select>
                </div>
              </div>

              <div class="columns-wrapper input-fields final-step-columns">
                <div class="col-12">
                  <label for="comment">Poznámka</label>
                  <textarea id="comment" name="comment" v-model="comment" @change="this.localform.comment = this.comment"></textarea>
                </div>
              </div>

              <div class="columns-wrapper input-fields submit final-step-columns">
                <div class="col-gdpr" v-if="!this.loggedIn">
                  <input class="styled-checkbox" id="gdpr" type="checkbox" value="gdpr" required>
                  <label for="gdpr" class="styled-checkbox-label">Súhlasím so <a href="https://www.hornet.sk/zasady-ochrany-osobnych-udajov/" target="_blank">spracovaním osobných údajov</a> pre vytvorenie cenovej ponuky</label>
                </div>
                <div class="col-btn">
                  <button class="app-btn" type="submit"><span>Dokončiť</span></button>
                  <button class="app-btn app-btn-blue" type="submit" @click.prevent="sendOrder" v-if="this.loggedIn"><span>Objednať</span></button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-6 zhrnutie">
            <h3>Zhrnutie</h3>
            <div class="zhrnutie-items" id="summary-one">
              <h5>Váš problém:</h5>
              <p><span class="odpoved-title">Poškodené:</span> <a class="odpoved-text" id="summary-service" @click="jumpToPage('stepOne')"></a></p>
              <p v-if="this.localform.size !== ''">
                <span class="odpoved-title">Veľkosť: </span>
                <a class="odpoved-text" id="summary-size" @click="jumpToPage('stepThree')">{{ this.form_data.size[this.localform.size] }}</a>
              </p>

              <p v-if="this.localform.place !== ''">
                <span class="odpoved-title">6cm od okraja: </span>
                <a class="odpoved-text" id="summary-place" @click="jumpToPage('stepFour')">{{ this.form_data.place[this.localform.place] }}</a>
              </p>

              <p v-if="this.localform.viewing_angle !== ''">
                <span class="odpoved-title">V zornom poli: </span>
                <a class="odpoved-text" id="summary-viewing-angle" @click="jumpToPage('stepFive')">{{ this.form_data.viewing_angle[this.localform.viewing_angle] }}</a>
              </p>
            </div>
            <div class="zhrnutie-items" id="summary-two">
              <h5>Poistenie:</h5>
              <p><span class="odpoved-title">Poistenie:</span> <a class="odpoved-text" id="summary-insurance" @click="jumpToPage('stepSix')"></a></p>
              <p><span class="odpoved-title">Poisťovňa:</span> <a class="odpoved-text" id="summary-insurer" @click="jumpToPage('stepSix')"></a></p>
            </div>
            <div class="zhrnutie-items" id="summary-three">
              <h5>Servisné miesto a termín:</h5>
              <p><span class="odpoved-title">PSČ miesta opravy:</span> <a class="odpoved-text" id="summary-repair-zip" @click="jumpToPage('stepEightA')"></a></p>
              <p><span class="odpoved-title">Vybraný servis:</span> <a class="odpoved-text" id="summary-repair-place" @click="jumpToPage('stepEightA')"></a></p>
              <p><span class="odpoved-title">Oprava na mieste, ulica:</span> <a class="odpoved-text" id="summary-onsite-street" @click="jumpToPage('stepEightB')"></a></p>
              <p><span class="odpoved-title">Oprava na mieste, mesto:</span> <a class="odpoved-text" id="summary-onsite-city" @click="jumpToPage('stepEightB')"></a></p>
              <p><span class="odpoved-title">Oprava na mieste, PSČ:</span> <a class="odpoved-text" id="summary-onsite-zip" @click="jumpToPage('stepEightB')"></a></p>
              <p><span class="odpoved-title">Oprava na mieste, poznámka:</span> <a class="odpoved-text" id="summary-onsite-details" @click="jumpToPage('stepEightB')"></a></p>
              <p><span class="odpoved-title">Termín:</span> <a class="odpoved-text" id="summary-date" @click="jumpToPage('stepNine')"></a></p>
            </div>
            <p class="suhlas-text" v-if="!this.loggedIn">V súlade s § 7 ods. 2 zákona č. 428/2002 Z. z. o ochrane osobných údajov dávam týmto svoj súhlas na spracovanie svojich osobných údajov,
              za účelom oboznamovania ma, s novinkami, súťažami a aktivitami. Tento súhlas je platný až do jeho odhlásenia. Potvrdzujem, že osobné údaje v rozsahu, v akom
              boli poskytnuté, sú pravdivé a správne.</p>
            <br>
            <h3 v-if="this.loggedIn">Volať</h3>
            <VueCtkDateTimePicker
                v-if="this.loggedIn"
                v-model="call"
                name="call"
                label="Dátum a čas volania"

                :noButton=true
                :format="'YYYY-MM-DD HH:mm'"
                :first-day-of-week="this.firstDayOfWeek"
                :min-date="this.minDate"
                :max-date="this.maxDate"
                :disabled-dates="this.disabledDates"
                :only-date="false"
                :minuteInterval=1
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

  let date = new Date();
  let min_date = date.toISOString();

  date.setMonth(date.getMonth() + 6);
  let max_date = date.toISOString();

  export default {
    name: 'StepTen',
    components: {
      VueCtkDateTimePicker,
    },
    emits: 'switch-page',
    props: ['form', 'form_data', 'loggedIn'],
    data() {
      return {
        localform: this.form,
        name: this.form.name,
        spz: this.form.spz,
        phone: this.form.telephone,
        email: this.form.email,
        comment: this.form.comment,
        vin: this.form.vin,
        eucode: this.form.eucode,
        resolved: this.form.resolved,
        wipers: this.form.wipers,
        call: '',
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        service: this.form.service,
        size: this.form.size,
        place: this.form.place,
        viewing_angle: this.form.viewing_angle,
        insurance: this.form.insurance,
        insurance_pos: this.form.insurance,
        service_place: this.form.service_place,
        mobile_street: this.form.mobile_street,
        mobile_city: this.form.mobile_city,
        mobile_zip: this.form.mobile_zip,
        mobile_details: this.form.mobile_details,
        service_point: this.form.service_point,
        date: this.form.date,
        calendar: this.form.date,
        service_place_id: this.form.service_place_id,
        disabledDates: this.getFreeDays(),
        firstDayOfWeek: "1",
        status: "new",
        minDate: min_date,
        maxDate: max_date
      }
    },
    methods: {
      sendOrder() {
        if (confirm("Týmto vytvoríte záväznú objednávku v systéme, odošlete objednávku do KIS a zákazníkovi bude odoslaný email a SMS správa.")) {
          this.$refs.form.status.value = "order";
          this.$refs.form.submit()
        }
      },
      jumpToPage(page) {
        this.$emit('switch-page', page)
      },
      fillSummary() {
        document.getElementById('summary-service').innerHTML = this.form_data.service[this.localform.service];

        if (this.localform.insurance == 0) {
          document.getElementById('summary-insurance').innerHTML = 'Nie';
          document.getElementById('summary-insurer').parentNode.style.display = 'none';
        } else {
          document.getElementById('summary-insurance').innerHTML = 'Áno';
          document.getElementById('summary-insurer').innerHTML = this.form_data.insurance[this.localform.insurance];
        }

        if (this.localform.insurance > 0 && this.loggedIn == true) {
          this.resolved = 2;
          this.localform.resolved = 2;
        }

        if (this.localform.service_place == 0) {
          document.getElementById('summary-repair-zip').parentNode.style.display = 'none';
          document.getElementById('summary-repair-place').parentNode.style.display = 'none';

          document.getElementById('summary-onsite-street').innerHTML = this.localform.mobile_street;
          document.getElementById('summary-onsite-city').innerHTML = this.localform.mobile_city;
          document.getElementById('summary-onsite-zip').innerHTML = this.localform.mobile_zip;
          if (this.localform.mobile_details != '') {
            document.getElementById('summary-onsite-details').innerHTML = this.localform.mobile_details;
          } else {
            document.getElementById('summary-onsite-details').parentNode.style.display = 'none';
          }
        } else {
          document.getElementById('summary-repair-zip').innerHTML = this.localform.zip;
          document.getElementById('summary-repair-place').innerHTML = this.localform.service_place_address;

          document.getElementById('summary-onsite-street').parentNode.style.display = 'none';
          document.getElementById('summary-onsite-city').parentNode.style.display = 'none';
          document.getElementById('summary-onsite-zip').parentNode.style.display = 'none';
          document.getElementById('summary-onsite-details').parentNode.style.display = 'none';
        }

        let date = new Date(this.localform.date);

        if (!this.loggedIn) {
          document.getElementById('summary-date').innerHTML = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
        } else {
          document.getElementById('summary-date').innerHTML = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ', ' + date.getHours() + ':' + ("0" + date.getMinutes()).slice(-2);
        }

      },
      getFreeDays() {
        var inactiveDays = [];
        let hardSetHolidays = [
          {'month': 1, 'day': 1},
          {'month': 1, 'day': 6},
          {'month': 5, 'day': 1},
          {'month': 5, 'day': 8},
          {'month': 7, 'day': 5},
          {'month': 8, 'day': 29},
          {'month': 9, 'day': 1},
          {'month': 9, 'day': 15},
          {'month': 11, 'day': 1},
          {'month': 11, 'day': 17},
          {'month': 12, 'day': 24},
          {'month': 12, 'day': 25},
          {'month': 12, 'day': 26},
        ];

        let weakSetHolidays = [
          '2023-04-07', '2023-04-10',
          '2024-03-26', '2024-04-01',
          '2025-04-18', '2025-04-21',
          '2026-04-03', '2026-04-06',
          '2027-03-26', '2027-03-29',
          '2028-04-14', '2028-04-17',
          '2029-03-30', '2029-04-02',
          '2030-04-19', '2030-04-22',
          '2031-04-11', '2031-04-14',
          '2032-03-26', '2032-03-29',
          '2033-04-15', '2033-04-18',
          '2034-04-07', '2034-04-10',
          '2035-03-23', '2035-03-26',
          '2036-04-11', '2036-04-14'
        ];

        var startDate = new Date();
        var endDate = new Date();
        endDate.setMonth(endDate.getMonth() + 6);

        for (var d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
          if (d.getDay() === 0) {
            inactiveDays.push(d.toISOString().split('T')[0]);
          }

          for (const i in hardSetHolidays) {
            if (hardSetHolidays[i]['month'] == d.getMonth()+1) {
              if (hardSetHolidays[i]['day'] == d.getDate()) {
                inactiveDays.push(d.toISOString().split('T')[0]);
              }
            }
          }

          if (weakSetHolidays.includes(d.toISOString().split('T')[0])) {
            inactiveDays.push(d.toISOString().split('T')[0]);
          }
        }

        return inactiveDays;
      }
    },
    mounted: function() {
      this.fillSummary();
    }
  }
</script>


