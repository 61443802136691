<template>
  <div class="container">
    <div class="main-bgr"></div>
    <div class="section">
      <div class="row in-grid">
        <div class="columns-wrapper center title">
          <div class="col-12">
            <h3>Vyberte si dátum, ktorý Vám najlepšie vyhovuje</h3>
          </div>
        </div>
        <div class="columns-wrapper center calendar">
          <div class="col-12">
            <VueCtkDateTimePicker
                v-if="!this.loggedIn"
                v-model="calendar"
                :inline=true
                :noButton=true
                :format="'YYYY-MM-DD'"
                :first-day-of-week="this.firstDayOfWeek"
                :min-date="this.minDate"
                :max-date="this.maxDate"
                :disabled-dates="this.disabledDates"
                :only-date="true"
            />

            <VueCtkDateTimePicker
                v-if="this.loggedIn"
                v-model="calendar"
                :inline=true
                :noButton=true
                :format="'YYYY-MM-DD HH:mm'"
                :first-day-of-week="this.firstDayOfWeek"
                :min-date="this.minDate"
                :max-date="this.maxDate"
                :disabled-dates="this.disabledDates"
                :disabled-hours="['00','01','02','03','04','05','06','07','19','20','21','22','23']"
                :only-date="false"
                :minuteInterval=15
            />
          </div>
        </div>
        <div class="columns-wrapper center">
          <div class="col-12">
            <a class="app-btn" href="#" @click="nextPage"><span>Potvrdiť</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

  let date = new Date();
  let min_date = date.toISOString();

  date.setMonth(date.getMonth() + 6);
  let max_date = date.toISOString();

  export default {
    name: 'StepNine',
    components: {
      VueCtkDateTimePicker,
    },
    emits: 'switch-page',
    props: ['form', 'loggedIn'],
    data() {
      return {
        localform: this.form,
        calendar: '',
        disabledDates: this.getFreeDays(),
        firstDayOfWeek: "1",
        date: new Date(),
        minDate: min_date,
        maxDate: max_date
      }
    },
    methods: {
      nextPage() {
        if (this.calendar == '') {
          alert('Vyberte prosím preferovaný dátum pre rezerváciu.')
        } else {
          this.localform.date = this.calendar;
          this.$emit('switch-page', 'StepTen');
        }
      },
      getFreeDays() {
        var inactiveDays = [];
        let hardSetHolidays = [
          {'month': 1, 'day': 1},
          {'month': 1, 'day': 6},
          {'month': 5, 'day': 1},
          {'month': 5, 'day': 8},
          {'month': 7, 'day': 5},
          {'month': 8, 'day': 29},
          {'month': 9, 'day': 1},
          {'month': 9, 'day': 15},
          {'month': 11, 'day': 1},
          {'month': 11, 'day': 17},
          {'month': 12, 'day': 24},
          {'month': 12, 'day': 25},
          {'month': 12, 'day': 26},
        ];

        let weakSetHolidays = [
          '2023-04-07', '2023-04-10',
          '2024-03-29', '2024-04-01',
          '2025-04-18', '2025-04-21',
          '2026-04-03', '2026-04-06',
          '2027-03-26', '2027-03-29',
          '2028-04-14', '2028-04-17',
          '2029-03-30', '2029-04-02',
          '2030-04-19', '2030-04-22',
          '2031-04-11', '2031-04-14',
          '2032-03-26', '2032-03-29',
          '2033-04-15', '2033-04-18',
          '2034-04-07', '2034-04-10',
          '2035-03-23', '2035-03-26',
          '2036-04-11', '2036-04-14'
        ];

        var startDate = new Date();
        var endDate = new Date();
        endDate.setMonth(endDate.getMonth() + 6);

        for (var d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
          if (d.getDay() === 0) {
            inactiveDays.push(d.toISOString().split('T')[0]);
          }

          if (!this.loggedIn && d.getDay() === 6) {
            inactiveDays.push(d.toISOString().split('T')[0]);
          }

          for (const i in hardSetHolidays) {
            if (hardSetHolidays[i]['month'] == d.getMonth()+1) {
              if (hardSetHolidays[i]['day'] == d.getDate()) {
                inactiveDays.push(d.toISOString().split('T')[0]);
              }
            }
          }

          if (weakSetHolidays.includes(d.toISOString().split('T')[0])) {
            inactiveDays.push(d.toISOString().split('T')[0]);
          }
        }

        return inactiveDays;
      }
    },
    mounted() {
      console.log(this.loggedIn);
    }
  }
</script>


