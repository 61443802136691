<template>
  <div class="container">
    <div class="main-bgr"></div>
    <div class="section">
      <div class="row in-grid">
        <div class="columns-wrapper center title">
          <div class="col-12">
            <h3>Ktoré sklo je poškodené?</h3>
            <p>Toto nám hovorí, akú prácu treba vykonať.</p>
          </div>
        </div>
        <div class="columns-wrapper center boxes">
          <div class="col-4">
            <div class="box" @click="selected(0, $event)">
              <img src="/assets/images/icon-krok1-celne-sklo.svg">
              <p>Čelné sklo</p>
            </div>
          </div>
          <div class="col-4">
            <div class="box" @click="selected(1, $event)">
              <img src="/assets/images/icon-krok1-bocne-sklo.svg">
              <p>Bočné sklo</p>
            </div>
          </div>
          <div class="col-4">
            <div class="box" @click="selected(2, $event)">
              <img src="/assets/images/icon-krok1-zadne-sklo.svg">
              <p>Zadné sklo</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StepOne',
    emits: 'switch-page',
    props: ['form'],
    data() {
      return {
        localform: this.form
      }
    },
    methods: {
      selected(id, event) {
        for (const element of document.querySelectorAll('.box')) {
          element.classList.remove("active");
        }

        if (event.target.localName != 'div') {
          event.target.parentNode.classList.toggle('active');
        } else {
          event.target.classList.toggle('active');
        }
        this.localform.service = id;
        this.$emit('switch-page', 'StepTwo');
      }
    }
  }
</script>

<style scoped>
  .main-menu ul {
    margin-left: auto;
    margin-right: auto;
  }
</style>
