<template>
  <div class="container">
    <div class="main-bgr"></div>
    <div class="section">
      <div class="row in-grid">
        <div class="columns-wrapper center title">
          <div class="col-12">
            <h3>Aké veľké je poškodenie?</h3>
            <p>Poškodenie menšie ako 2 EUR minca, dokážeme opraviť bez nutnosti výmeny!</p>
          </div>
        </div>
        <div class="columns-wrapper center boxes">
          <div class="col-4">
            <div class="box" @click="selected(0)">
              <img src="/assets/images/icon-krok3-mensie2.svg">
              <p>Menšie ako 2€ minca</p>
            </div>
          </div>
          <div class="col-4">
            <div class="box" @click="selected(1)">
              <img src="/assets/images/icon-krok3-vacsie2.svg">
              <p>Väčšie ako 2€ minca</p>
            </div>
          </div>
          <div class="col-4">
            <div class="box" @click="selected(2)">
              <img src="/assets/images/icon-krok3-vacsia.svg">
              <p>Väčšia prasklina</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StepThree',
    emits: 'switch-page',
    props: ['form'],
    data() {
      return {
        localform: this.form
      }
    },
    methods: {
      selected(id) {
        this.localform.size = id;
        if (id > 0) {
          this.$emit('switch-page', 'StepSix');
        } else {
          this.$emit('switch-page', 'StepFour');
        }
      }
    },
    mounted() {
      this.localform.size = '';
    }
  }
</script>


