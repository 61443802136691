<template>
  <HeaderPage :back-button="backButton"
              :step="step"
              :prevpage="previousPage"
              :user="user"
              :loggedIn="callHelp"
              @switch-page="switchPage"/>

  <main class="main-content client" id="main-container">
    <component :is="activePage"
               :form="form"
               :form_data="form_data"
               :loggedIn="callHelp"
               @switch-page="switchPage"></component>
    <div id="pomocnetexty-wrapper">
      <p id="helping-text"></p>
    </div>
  </main>
  <FooterPage :form_data="form_data"
              :loggedIn="callHelp"
              :form="form"/>
</template>


<script>

import '/src/assets/spj2eof.css'
import '/public/assets/css/style.css'

import HeaderPage from './components/common/HeaderPage.vue'
import FooterPage from './components/common/FooterPage.vue'
import StepOne from './components/Step_1.vue'
import StepTwo from './components/Step_2.vue'
import StepThree from './components/Step_3.vue'
import StepFour from './components/Step_4.vue'
import StepFive from './components/Step_5.vue'
import StepSix from './components/Step_6.vue'
import StepSeven from './components/Step_7.vue'
import StepEightA from './components/Step_8A.vue'
import StepEightB from './components/Step_8B.vue'
import StepEightC from './components/Step_8C.vue'
import StepNine from './components/Step_9.vue'
import StepTen from './components/Step_10.vue'
import StepEleven from './components/Step_11.vue'


export default {
  name: 'App',
  components: {
    HeaderPage,
    FooterPage,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepSix,
    StepSeven,
    StepEightA,
    StepEightB,
    StepEightC,
    StepNine,
    StepTen,
    StepEleven,
  },
  data() {
    return {
      backButton: false,
      activePage: 'StepOne',
      previousPage: 'StepOne',
      callHelp: false,
      rqid: 0,
      step: 1,
      screen: 1,
      user: {
        'first_name': '',
        'last_name': '',
        'avatar': '',
        'role': '',
      },
      form_data: {
        'service': [
            'Čelné sklo',
            'Bočné sklo',
            'Zadné sklo'
        ],
        'size': [
            'Menšie ako 2€ minca',
            'Väčšie ako 2€ minca',
            'Väčšia prasklina'
        ],
        'place': [
            'Viac ako 6cm od okraja',
            'Menej ak 6cm od okraja'
        ],
        'viewing_angle': [
            'Áno, je v zornom poli',
            'Nie je v zornom poli'
        ],
        'insurance': [
            'Bez poistenia',
            'Allianz',
            'Kooperativa',
            'Uniqa',
            'Komunálna poisťovňa',
            'Groupama',
            'ČSOB',
            'Generali',
            'Union',
            'AXA',
            'Wüstenrot',
            'Genertel.sk'
        ],
        'service_place': [
            'Na pobočke Autoskla Hornet',
            'Mobilný servis',
        ]
      },
      form: {
        'service': '',
        'telephone': '',
        'size': '',
        'place': '',
        'viewing_angle': '',
        'insurance': '',
        'service_place': '',
        'service_place_id': '',
        'service_place_address': '',
        'mobile_street': '',
        'mobile_city': '',
        'mobile_zip': '',
        'mobile_details': '',
        'service_point': '',
        'date': '',
        'zip': '',
        'name': '',
        'spz': '',
        'phone': '',
        'email': '',
        'comment': '',
        'vin': '',
        'eucode': '',
        'resolved': '',
        'wipers': '',
        'call': ''
      }
    }
  },
  methods: {
    switchPage(page) {
      if (page == 'StepOne') {
        this.backButton = false;
      } else {
        this.backButton = true;
      }

      this.getSupportText(page);

      switch (page) {
        case 'StepOne':
          this.backButton = false;
          this.step = 1;
          this.previousPage = 'StepOne';
          break;
        case 'StepTwo':
          this.backButton = true;
          this.step = 1;
          this.previousPage = 'StepOne';
          break;
        case 'StepThree':
          this.backButton = true;
          this.step = 1;
          this.previousPage = 'StepTwo';
          this.saveRequest();
          break;
        case 'StepFour':
          this.backButton = true;
          this.step = 1;
          this.previousPage = 'StepThree';
          this.saveRequest();
          break;
        case 'StepFive':
          this.backButton = true;
          this.step = 1;
          this.previousPage = 'StepFour';
          this.saveRequest();
          break;
        case 'StepSix':
          this.backButton = true;
          this.step = 2;
          if (this.form.service > 1) {
            this.previousPage = 'StepTwo';
          } else if (this.form.size > 1) {
            this.previousPage = 'StepThree';
          } else if (this.form.place > 1) {
            this.previousPage = 'StepFour';
          } else {
            this.previousPage = 'StepFive';
          }
          this.saveRequest();
          break;
        case 'StepSeven':
          this.backButton = true;
          this.step = 3;
          this.previousPage = 'StepSix';
          this.saveRequest();
          break;
        case 'StepEightA':
          this.backButton = true;
          this.step = 3;
          this.previousPage = 'StepSeven';
          this.saveRequest();
          break;
        case 'StepEightB':
          this.backButton = true;
          this.step = 3;
          this.previousPage = 'StepSeven';
          this.saveRequest();
          break;
        case 'StepEightC':
          this.backButton = true;
          this.step = 3;
          this.previousPage = 'StepSeven';
          this.saveRequest();
          break;
        case 'StepNine':
          this.backButton = true;
          this.step = 3;
          this.previousPage = 'StepEightA';
          this.saveRequest();
          break;
        case 'StepTen':
          this.backButton = true;
          this.step = 4;
          this.previousPage = 'StepNine';
          this.saveRequest();
          break;
        case 'StepEleven':
          this.backButton = true;
          this.step = 5;
          this.previousPage = 'StepEleven';
          break;
      }

      this.activePage = page;
    },
    getSupportText(page) {
      if (this.callHelp) {
        var uri = '/support-text?screen=';
        if (process.env.VUE_APP_ROOT_API !== undefined) {
          uri = process.env.VUE_APP_ROOT_API+uri;
        }
        fetch(uri + page + '&place=' + this.form.service + '&type=' + this.form.viewing_angle + '&insurance=' + this.form.insurance)
            .then(
                response => response.json()
            ).then(data => {
          let obj = data;
          document.getElementById('helping-text').innerHTML = obj;
        });
      }
    },
    saveRequest() {
      if (!this.callHelp) {
        let currentObj = this;
        this.axios.post('/save-rq', {
          data: this.form,
          id: this.rqid,
          csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }).then(function (response) {
          currentObj.rqid = response.data.rqid;
        });
      }
    }
  },
  mounted() {
    var uri = '/order';
    if (process.env.VUE_APP_ROOT_API !== undefined) {
      uri = process.env.VUE_APP_ROOT_API+uri;
    }

    fetch(uri)
      .then(
        response => response.json()
      ).then(data => {
        let obj = data;
        this.callHelp = obj.status;
        if (obj.status != false) {
          this.user.first_name = obj.first_name;
          this.user.last_name = obj.last_name;
          this.user.role = obj.role;
          this.user.avatar = obj.avatar;
          document.getElementById('footer').style.display = 'flex';
          document.getElementById('main-container').classList.remove('client');
          this.getSupportText('StepOne');
        }
    })
  }
}
</script>

