<template>
  <div class="container">
    <div class="main-bgr"></div>
    <div class="section">
      <div class="row in-grid">
        <div class="columns-wrapper center title">
          <div class="col-12">
            <h3>Vyberte si servis, ktorý Vám najviac vyhovuje</h3>
          </div>
        </div>
        <div class="columns-wrapper boxes">
          <div class="col-2" v-if="this.servicepoints.length < 2"></div>
          <div class="col-2" v-if="this.servicepoints.length == 0"></div>

          <div class="box service-box col-4" v-for="(servicepoint, index) in this.servicepoints" :key="index">
            <div class="servis row" @click="servicePoint(index)">
              <p class="servis-title" ><span class="city">{{ servicepoint.city }}</span><br> {{ servicepoint.name }}</p>
              <p class="servis-address">{{ servicepoint.address }}</p>
            </div>
            <div class="row map-link">
              <a class="servis-link" @click="link(servicepoint.map)" target="_blank"><span>Zobraziť na mape</span></a>
            </div>
          </div>

          <div class="col-4 service-box">
            <div class="box servis service-box-last" @click="mobileService">
              <p class="servis-title"><span class="city">Mobilný servis</span><br> Autosklo Hornet®</p>
              <p class="servis-address">U vás doma alebo v práci</p>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StepEightC',
    emits: 'switch-page',
    props: ['form'],
    data() {
      return {
        localform: this.form,
        servicepoints: []
      }
    },
    methods: {
      mobileService() {
        this.localform.service_place = 0;
        this.$emit('switch-page', 'StepEightB');
      },
      servicePoint(index) {
        this.localform.service_place = 1;
        this.localform.service_place_id = this.servicepoints[index].id;
        this.localform.service_place_address = this.servicepoints[index].name + ', ' + this.servicepoints[index].address + ', ' + this.servicepoints[index].city;
        this.$emit('switch-page', 'StepNine');
      },
      link(url) {
        window.open(url, '_blank').focus();
      }
    },
    mounted() {
      var uri = '/zip?zip=';
      if (process.env.VUE_APP_ROOT_API !== undefined) {
        uri = process.env.VUE_APP_ROOT_API+uri;
      }

      fetch(uri+this.localform.zip)
          .then(
              response => response.json()
          ).then(data => {
            let obj = data;
            this.servicepoints = obj;
          })
    }
  }
</script>


