<template>
  <header id="header" role="banner" class="resp-hidden-sm">

    <div class="logo">
      <a href="https://www.hornet.sk">
        <img src="/assets/images/logo-hornet.svg">
      </a>
    </div>

    <div class="main-menu">
      <nav id="menu" :key="componentKey">
        <ul v-if="this.step < 5">
          <a href="#" class="krok-spat" @click="goBack" v-if="this.backButton"><span>Krok späť</span></a>
          <li class="menu-item" :class="{ active: this.step == 1, disable: this.step > 1 }"><a href="#"><span>Váš problém</span></a></li>
          <li class="menu-item" :class="{ active: this.step == 2, disable: this.step > 2 }"><a href="#"><span>Poistenie</span></a></li>
          <li class="menu-item" :class="{ active: this.step == 3, disable: this.step > 3 }"><a href="#"><span>Servisné miesto</span></a></li>
          <li class="menu-item" :class="{ active: this.step == 4 }"><a href="#"><span>Zhrnutie</span></a></li>
        </ul>
      </nav>
    </div>

      <div class="header-right">
        <div class="operator phone" v-if="this.loggedIn === false">
          <img class="icon-tel" src="/assets/images/icon-tel.svg">
          <div class="operator-title">
            <span class="name"><a href="tel:0800102020">0800 10 20 20</a></span><br>
            <span class="position">Po - Pi 8:00 - 20:00</span>
          </div>
        </div>

        <div class="operator" v-if="this.loggedIn === true">
          <img class="operator-image" :src="this.user.avatar">
          <div class="operator-title">
            <span class="name"><a href="/admin">{{ this.user.first_name }} {{ this.user.last_name }}</a></span><br>
            <span class="position"><a href="/admin">{{ this.user.role }}</a></span>
          </div>
        </div>
      </div>

  </header>

  <header id="header" role="banner" class="resp-display-sm">

    <div class="logo">
      <img src="/assets/images/logo-hornet.svg">
    </div>

    <div class="header-right">
      <div class="operator phone" v-if="this.loggedIn === false">
        <img class="icon-tel" src="/assets/images/icon-tel.svg">
        <div class="operator-title">
          <span class="name"><a href="tel:0800102020">0800 10 20 20</a></span><br>
          <span class="position">Po - Pi 8:00 - 20:00</span>
        </div>
      </div>

      <div class="operator" v-if="this.loggedIn === true">
        <img class="operator-image" :src="this.user.avatar">
        <div class="operator-title">
          <span class="name"><a href="/admin">{{ this.user.first_name }} {{ this.user.last_name }}</a></span><br>
          <span class="position"><a href="/admin">{{ this.user.role }}</a></span>
        </div>
      </div>
    </div>

    <div class="break"></div>

    <div class="main-menu">
      <nav id="menu" :key="componentKey">
        <ul v-if="this.step < 5">
          <a href="#" class="krok-spat" @click="goBack" v-if="this.backButton"><span>Krok späť</span></a>
          <li class="menu-item" :class="{ active: this.step == 1, disable: this.step > 1 }"><a href="#"><span>Váš problém</span></a></li>
          <li class="menu-item" :class="{ active: this.step == 2, disable: this.step > 2 }"><a href="#"><span>Poistenie</span></a></li>
          <li class="menu-item" :class="{ active: this.step == 3, disable: this.step > 3 }"><a href="#"><span>Servisné miesto</span></a></li>
          <li class="menu-item" :class="{ active: this.step == 4 }"><a href="#"><span>Zhrnutie</span></a></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
  export default {
    name: 'HeaderPage',
    emits: 'switch-page',
    props: ['backButton', 'step', 'prevpage', 'loggedIn', 'user'],
    data() {
      return {
        componentKey: 0
      }
    },
    methods: {
      goBack() {
        this.$emit('switch-page', this.prevpage);
        this.forceRerender();
      },
      forceRerender() {
        this.componentKey += 1
      }
    }
  }
</script>