import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import VueGtag from 'vue-gtag';
import axios from 'axios'
import VueAxios from 'vue-axios'


const router = createRouter({
    history: createWebHistory(),
    routes: [

    ]
});

let app = createApp(App)
app.mount('#app')
app.use(VueGtag, {
    config: {
        id: "GTM-3GL7S",
        vueRouter: router,
        params: {
            anonymize_ip: true
        }
    }
})
app.use(VueAxios, axios)