<template>
  <div class="container">
    <div class="main-bgr"></div>
    <div class="section">
      <div class="row in-grid">
        <div class="columns-wrapper center title">
          <div class="col-12">
            <h3>Zadajte PSČ miesta, kde sa nachádzate</h3>
          </div>
        </div>
        <div class="columns-wrapper center">
          <div class="col-4"></div>
          <div class="col-4">
            <div class="input-field">
              <input type="text" id="psc" name="psc" placeholder="PSČ" @keydown.enter="nextPage" v-model="zip">
            </div>
            <a class="app-btn" href="#" @click="nextPage"><span>Nájsť pobočku</span></a>
          </div>
          <div class="col-4"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StepEightA',
    emits: 'switch-page',
    props: ['form'],
    data() {
      return {
        localform: this.form,
        zip: this.form.zip
      }
    },
    methods: {
      nextPage() {
        this.localform.zip = this.zip;
        this.$emit('switch-page', 'StepEightC');
      }
    }
  }
</script>


