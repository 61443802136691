<template>
  <div class="container">
    <div class="main-bgr"></div>
    <div class="section">
      <div class="row in-grid">
        <div class="columns-wrapper center title">
          <div class="col-12">
            <h3>Je poškodenie viac ako 6 cm od okraja skla?</h3>
            <p>Pokiaľ je poškodenie príliš blízko okraja, bude nutné čelné sklo vymeniť.</p>
          </div>
        </div>
        <div class="columns-wrapper center boxes">
          <div class="col-6">
            <div class="box" @click="selected(0)">
              <img src="/assets/images/icon-krok4-okraj-viac.svg">
              <p>Viac ako 6 cm od okraja</p>
            </div>
          </div>
          <div class="col-6">
            <div class="box" @click="selected(1)">
              <img src="/assets/images/icon-krok4-okraj-menej.svg">
              <p>Menej ako 6 cm od okraja</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StepFour',
    emits: 'switch-page',
    props: ['form'],
    data() {
      return {
        localform: this.form
      }
    },
    methods: {
      selected(id) {
        this.localform.place = id;
        if (id > 0) {
          this.$emit('switch-page', 'StepSix');
        } else {
          this.$emit('switch-page', 'StepFive');
        }
      }
    },
    mounted() {
      this.localform.place = '';
    }
  }
</script>


