<template>
  <footer id="footer">
    <ul class="footer-list">
      <li class="footer-item">
        <span class="odpoved-title">Poškodené:</span><br>
        <span class="odpoved-text">{{ this.form_data.service[this.form.service] }}</span>
      </li>
      <li class="footer-item">
        <span class="odpoved-title">Veľkosť:</span><br>
        <span class="odpoved-text">{{ this.form_data.size[this.form.size] }}</span>
      </li>
      <li class="footer-item">
        <span class="odpoved-title">6cm od okraja:</span><br>
        <span class="odpoved-text">{{ this.form_data.place[this.form.place] }}</span>
      </li>
      <li class="footer-item">
        <span class="odpoved-title">V zornom poli:</span><br>
        <span class="odpoved-text">{{ this.form_data.viewing_angle[this.form.viewing_angle] }}</span>
      </li>

      <li class="footer-item" v-if="this.form.insurance > 0">
        <span class="odpoved-title">Poistenie:</span><br>
        <span class="odpoved-text" v-if="this.form.insurance === 1">{{ this.form_data.insurance[this.form.insurance] }}</span>
        <span class="odpoved-text" v-else>Áno</span>
      </li>

      <li class="footer-item">
        <span class="odpoved-title">Poisťovňa:</span><br>
        <span class="odpoved-text" v-if="this.form.insurance === 1">-</span>
        <span class="odpoved-text" v-else>{{ this.form_data.insurance[this.form.insurance] }}</span>
      </li>

      <li class="footer-item">
        <span class="odpoved-title">PSČ miesta opravy:</span><br>
        <span class="odpoved-text">{{ this.form.zip }}</span>
      </li>
<!--      <li class="footer-item">-->
<!--        <span class="odpoved-title">Vybraný servis:</span><br>-->
<!--        <span class="odpoved-text"></span>-->
<!--      </li>-->
      <li class="footer-item">
        <span class="odpoved-title">Termín:</span><br>
        <span class="odpoved-text">{{ this.form.date }}</span>
      </li>
    </ul>

    <a class="new" href="#" @click="refreshOrder" title="Vymazať objednávku a začať znova">
      <img src="/assets/images/icon-refresh.svg">
    </a>

    <a class="pomoc" href="#" @click="toggleHelpContainer" title="Pomocné texty">
      <img src="/assets/images/icon-pomocnetexty.svg">
    </a>

    <a class="administracia" href="/admin" title="Administrácia">
      <img src="/assets/images/icon-home.svg">
    </a>
  </footer>
</template>

<script>
  export default {
    name: 'FooterPage',
    props: ['form', 'form_data', 'loggedIn'],
    data() {
      return {
      }
    },
    methods: {
      toggleHelpContainer() {
        if (document.getElementById('pomocnetexty-wrapper').style.display == 'block') {
          document.getElementById('pomocnetexty-wrapper').style.display = 'none';
        } else {
          document.getElementById('pomocnetexty-wrapper').style.display = 'block';
        }
      },
      refreshOrder() {
        location.reload();
      }
    },
    mounted: function() {
    }
  }
</script>